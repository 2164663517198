import { Link } from 'gatsby'
import React from 'react'

import PageHead from 'components/head/PageHead'
import Heading from 'components/heading/Heading'
import PageWrapper from 'components/layout/page-wrapper'
import LoadingSymbol from 'components/loading-symbol'
import { DOWNLOAD_URL, HELPERS_URL, SUPPORT_EMAIL } from 'src/constants'

import 'static/confirm-subscription/scss/styles.scss'

const LoadingContent = () => (
  <div className="x-content x__width">
    <LoadingSymbol />
  </div>
)

const SuccessContent = () => (
  <div className="x-content x__width">
    <div className="x-content__icon-success" />
    <Heading page="confirm-subscription" section="success" className="x-content__heading" />

    <p className="x-content__txt">
      You'll begin receiving tips and updates to make the most of your Exodus experience
    </p>

    <div className="x-content__buttons">
      <Link className="x__button x__button--secondary" to="/">
        <span className="x__button-align">
          <span className="x__button-align-text">Go to Exodus home page</span>
        </span>
      </Link>

      <a className="x__button" href={DOWNLOAD_URL}>
        <span className="x__button-align">
          <span className="x__button-align-text">Download Exodus</span>
        </span>
      </a>
    </div>
  </div>
)

const FailContent = () => (
  <div className="x-content x__width">
    <Heading page="confirm-subscription" section="error" className="x-content__heading" />

    <p className="x-content__txt">
      We were unable to confirm your subscription. Please email Exodus support and we'll add you
      manually to our subscriber list.
    </p>

    <div className="x-content__buttons">
      <Link className="x__button x__button--secondary" to="/">
        <span className="x__button-align">
          <span className="x__button-align-text">Go to Exodus home page</span>
        </span>
      </Link>

      <a
        className="x__button"
        href={`mailto:${SUPPORT_EMAIL}?subject=Exodus%20Newsletter:%20Failed%20to%20confirm%20subscription&body=Please%20add%20me%20to%20the%20subscriber%20list.`}
      >
        <span className="x__button-align">
          <span className="x__button-align-text">Email Exodus Support</span>
        </span>
      </a>
    </div>
  </div>
)

class JobApplicationPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context)

    this.state = {
      status: 'loading',
    }
  }

  componentDidMount() {
    const regex = /[?&]([^=#]+)=([^&#]*)/g
    const url = this.props.location.href
    const params = {}
    let match
    while ((match = regex.exec(url))) {
      params[match[1]] = decodeURI(match[2])
    }

    if (params.id) {
      fetch(`${HELPERS_URL}/users/confirm-subscription/${params.id}`)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ status: 'success' })
          } else {
            this.setState({ status: 'fail' })
          }
        })
        .catch((err) => {
          console.log(err)
          this.setState({ status: 'fail' })
        })
    } else {
      this.setState({ status: 'fail' })
    }
  }

  render() {
    const { status } = this.state

    return (
      <PageWrapper className="x-page-confirm-subscription" removeFooter>
        <div className="x__bg-container">
          <div />
        </div>

        {status === 'loading' ? (
          <LoadingContent />
        ) : status === 'success' ? (
          <SuccessContent />
        ) : (
          <FailContent />
        )}
      </PageWrapper>
    )
  }
}

export default JobApplicationPage

// <head> component:
export function Head() {
  return <PageHead page="confirm-subscription" nocache noindex />
}
